<template>
  <div class="page">
    <div class="wrapper illustration illustration_circle">
      <Backlink title="questionnaire" service="credit" backlink="/credit/delay" />
      <div class="content">
        <AboutDelay service="credit" nextlink="/credit/something-else" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutDelay from '@/components/service/AboutDelay.vue'

export default {
  name: 'Credit_about_delay',
  components: { Backlink, AboutDelay }
}
</script>
